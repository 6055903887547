
import { mapGetters } from 'vuex'
import NavItemsPure from './NavItems.json'
import { convertToSlug } from '~/helpers/generate-slug'
export default {
  data() {
    return {
      navItems: NavItemsPure,
      mobileTriggered: false,
      activeMenu: [],
      schoolNavBar: [
        {
          title: 'nav.about',
          to: '/hakkimda',
          sub: [],
        },
        {
          title: 'nav.eventListOfUs',
          to: '/etkinlik-listemiz',
          sub: [],
        },
        {
          title: 'nav.myprofile',
          sub: [],
          to: '/profilim',
          authorised: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCategories: 'categories/getCategories',
      getActiveUser: 'user/getActiveUser',
      getRedirectUrl: 'getRedirectUrl',
      getSettings: 'getSettings',
      getActiveLanguages: 'getActiveLanguages',
    }),
    menuNavBar() {
      if (this.getSettings.departmentId === 2) return this.schoolNavBar
      else return this.navItems
    },
  },
  methods: {
    convertToSlug,
    wc_hex_is_light(color) {
      const hex = color.replace('#', '')
      const c_r = parseInt(hex.substring(0, 0 + 2), 16)
      const c_g = parseInt(hex.substring(2, 2 + 2), 16)
      const c_b = parseInt(hex.substring(4, 4 + 2), 16)
      const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
      return brightness > 155
    },
    COLOR() {
      const themeColor = this.$store.state.settings.themeColor
      if (this.wc_hex_is_light(themeColor)) return '#000'
      else return '#fff'
    },
    SET_ACTIVE_MENU_TOGGLE(index, to) {
      const p = this.activeMenu.indexOf(index)
      if (p === -1) this.activeMenu.push(index)
      else this.activeMenu.splice(p, 1)
      console.log(to, 'trooo')
      if (to !== '' && to !== '#') this.SET_MOBILE_TOGGLE()
    },
    SET_MOBILE_TOGGLE() {
      this.mobileTriggered = !this.mobileTriggered
    },
    SET_CATEGORIES_TO_NAVBAR() {
      const categoryNav = this.navItems.findIndex(
        (item) => item.title === 'nav.categories'
      )
      if (categoryNav > -1) {
        this.navItems[categoryNav].sub = this.getCategories.map((category) => ({
          title: category.name,
          to: `/kategori/${category.id}-${this.convertToSlug(category.name)}`,
        }))
      }
    },
    // firmanın sahip olduğu eğitim tipleri getirilir.
    SET_PRODUCTS_OF_NAVBAR() {
      const productsNav = this.navItems.findIndex(
        (item) => item.title === 'nav.products'
      )
      this.navItems[productsNav].sub = this.navItems[productsNav].sub.filter(
        (sub) => {
          if (
            this.$store.state.settings.isCourses === 1 &&
            sub.title === 'type.courses'
          )
            return sub
          else if (
            this.$store.state.settings.isSeances === 1 &&
            sub.title === 'type.seances'
          )
            return sub
          else if (
            this.$store.state.settings.isBlog === 1 &&
            sub.title === 'type.blogs'
          )
            return sub
          else if (
            this.$store.state.settings.isBooks === 1 &&
            sub.title === 'type.books'
          )
            return sub
          else if (
            this.$store.state.settings.isEvents === 1 &&
            sub.title === 'type.events'
          )
            return sub
          return false
        }
      )
      const profileNav = this.navItems.findIndex(
        (item) => item.title === 'myProfile.myProfile'
      )
      console.log(profileNav)

      if (profileNav === -1) return
      this.navItems[profileNav].sub = this.navItems[profileNav].sub.filter(
        (sub) => {
          if (
            sub.title === 'myProfile.editProfile' ||
            sub.title === 'myProfile.changePassword'
          ) {
            return sub
          }
          if (
            (this.$store.state.settings.isCourses === 1 &&
              sub.title === 'myProfile.myCourses') ||
            (this.$store.state.settings.isCourses === 1 &&
              sub.title === 'myProfile.myQuizzes')
          )
            return sub
          else if (
            this.$store.state.settings.isSeances === 1 &&
            sub.title === 'myProfile.mySeances'
          )
            return sub
          else if (
            this.$store.state.settings.isBooks === 1 &&
            sub.title === 'myProfile.myBooks'
          )
            return sub
          else if (
            this.$store.state.settings.isEvents === 1 &&
            sub.title === 'myProfile.myEvents'
          )
            return sub
          else if (sub.title === 'logout') return sub
          return false
        }
      )
    },
    SET_CONTACT_TO_NAVBAR() {
      const contactView =
        this.$store.state.settings.contents.contactView
          .map((p) => {
            try {
              const forms = JSON.parse(p.overview)
              return {
                ...p,
                forms: forms.map((form) => ({
                  ...form,
                  value: '',
                })),
                buttonText: p.degrees || this.$t('submit'),
                checked: false,
              }
            } catch (error) {
              return {}
            }
          })
          .find(
            (p) => p.language.toLowerCase() === this.$i18n.locale.toLowerCase()
          ) || null
      if (!contactView) {
        const contact = this.navItems.findIndex(
          (item) => item.title === 'nav.contact'
        )
        if (contact > -1) this.navItems.splice(contact, 1)
      }
    },
  },
  created() {
    setTimeout(() => {
      this.SET_CATEGORIES_TO_NAVBAR()
    }, 150)
    this.SET_PRODUCTS_OF_NAVBAR()
    this.SET_CONTACT_TO_NAVBAR()
  },
}
